<template>
  <div class="login-page">
    <div class="login-box">
      <h1>拾伍OA班课业务系统</h1>
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="手机号" prop="userName">
          <el-input v-model="ruleForm.userName" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="'密\xa0\xa0\xa0\xa0码'" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="验证码" prop="captcha">
                    <div class="captcha-box">
                        <el-input v-model="ruleForm.captcha" autocomplete="off"></el-input>
                        <img class="img-code" src="https://oss.ke.live/images/gzh_qj.jpg" alt="">
                    </div>
                </el-form-item> -->
        <div class="btn-box">
          <el-button class="login-btn" type="primary" @click="submitForm('ruleForm')">登录</el-button>

        </div>
      </el-form>
    </div>

  </div>
</template>
<script>
import { validateUsername } from '../../utils/validate';

// import { UserLoginApi } from '../../request/api';
export default {
  data() {
    return {
      ruleForm: {
        userName: '', //用户名
        pass: 'a123456a', //密码
        // captcha:'', //验证码
      },
      rules: {
        userName: [
          { validator: validateUsername, trigger: 'blur', required: true }, //自定义验证
          // { required: true, message: '请输入用户名', trigger: 'blur' }, //自带验证
        ],
        pass: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        // captcha: [
        //     { required: true, message: '请输入', trigger: 'blur' }
        // ],
      },
    }
  },
  created() {

  },
  methods: {
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.request({
            method: 'post',
            url: '/manage/login',
            param: {
              phone: that.ruleForm.userName,
              passWord: that.ruleForm.pass,
            },
            success: function (res) {
              if (res && res.code == 200) {
                if (res.result) {
                  localStorage.setItem('hkmanage_session', res.result) //这个必须设置，否则router.js里一直跳转到登录
                }
                localStorage.setItem('hkmanage_username', that.ruleForm.userName)

                let willToPath = localStorage.getItem('hkmanage_path_to')
                console.log(localStorage, '------login.vue d登录成功之后---', willToPath)

                if (willToPath) {
                  that.$router.push(willToPath)
                } else {
                  that.$router.push('/')
                }
              } else {
                console.log(res, '------登录成功,但不是200');
                that.$message.error("登录失败，" + res.message)
              }
            },
            error: function (error) {
              //服务器异常
              console.log(error, '------登录失败error');
              that.$message.error('登录error。', error);
            }
          })

          // UserLoginApi({
          //     phone:that.ruleForm.userName,
          //     passWord:that.ruleForm.pass
          // }).then(res=>{
          //     debugger
          //     console.log(res,'----Login.vue login.api之后参数')
          //     // 登录成功后，提示登录成功；使用storage 保存 token；跳转首页；
          //     // 若有缓存清除缓存 localStoreage.removeItem
          //     if(res.code == 200){
          //         localStorage.setItem('hkmanage_username',that.ruleForm.userName)
          //         localStorage.setItem('hkmanage_session','token1111111111') //这个必须设置，否则router.js里一直跳转到登录


          //         let willToPath = localStorage.getItem('hkmanage_path_to')
          //         console.log(localStorage,'------login.vue d登录成功之后---',willToPath)

          //         if(willToPath){
          //             window.location.href = willToPath //这里不用replace,不然没有数据 debugger
          //         }else{
          //             window.location.href=window.location.origin+'/'
          //         }
          //     }else{
          //         this.$message.error(res.msg)
          //     }
          // }) 

        } else {
          // this.$message('请输入信息111111') //默认 type = info
          this.$message({
            message: '请输入信息',
            type: 'warning'
          })
          console.log('error submit!!');
          return false;
        }
      });

      /* //await 写法
      this.$refs[formName].validate(async (valid) => {
          if (valid) {
              let res = await UserLoginApi({
                  phone:that.ruleForm.userName,
                  passWord:that.ruleForm.pass
              })
              
              //这里继续写 UserLoginApi 调用之后的then方法内容
              if(res.code==200){ 继续执行其它内容 }else{ this.$message.error(res.msg) }
          } else {
              // this.$message('请输入信息111111') //默认 type = info
              this.$message({
                  message:'请输入信息',
                  type:'warning'
              })
              console.log('error submit!!');
              return false;
          }
      });
      */
    },

  }
}
</script>
<style lang="scss" scope>
.login-page {
  background: #f8f8f8;
  width: 100%;
  height: 100%;
  .login-box {
    width: 400px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #f8f8f8;
    box-shadow: 0 2px 2px #f8f8f8;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    padding: 28px 28px 28px 0;
    h1 {
      text-align: center;
      padding-bottom: 30px;
      margin-left: 28px; //因为login-box有个padding-right=28的距离，设置这个，让标题居中
    }
    .captcha-box {
      display: flex;
      .img-code {
        height: 32px;
        width: 80px;
        margin-left: 20px;
      }
    }
    .btn-box {
      margin-left: 40px;
      .login-btn {
        width: 100%;
      }
    }
  }
}
</style>